import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Conditions are the first half of a Rule execution. Every time an Event is received, Rules configured for that incoming
Event's specified Source are each evaluated based on their configured Condition. If the Condition evaluates as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{`,
the Rule's Actions are executed. If they evaluate as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{`, the Actions are ignored.`}</p>
    <p>{`Conditions make it easy to only perform certain Actions when certain things are true. Conditions can be as simple as
specifying the Event be of a certain type, or as complex as evaluating a Javascript function.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      